import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade, Reveal } from "react-reveal/";
import SectionTitleTwo from "./Banner/SectionTitleTwo";

class About extends Component {
  render() {
    let jhonData = this.props.jhonData;
    var { aClass } = this.props;
    return (
      <section className={`${aClass}`} id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <Fade bottom cascade duration={1000}>
                <div className="about_content">
                  <SectionTitleTwo
            tCenter="text-center"
            btitle="About Us"
          /> 

                  <p style={{ textAlign: "justify" }}>
                  We are a customer-first cloud-native software development team, employing state-of-the-art technology to uncover, create and capture value at scale. With utmost integrity and agile delivery, we are your partner in success. Our Expertise is in custom web and mobile application development, Product development, UI/UX design, and IT staff augmentation.
                  </p>
                  {/* <Link to="/" className="theme_btn active">
                    Hire Me
                  </Link>
                  <Link to="/" className="theme_btn">
                    Download CV
                  </Link> */}
                </div>
              </Fade>
            </div>
            <div className="col-lg-5">
              <div className="about_img">
                <Reveal effect="fadeInRight" duration={1500}>
                  <img src={require("../image/mobile.svg")} alt="" />
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
